<template>
  <div
    class="slider"
    data-cy="slider"
    :class="sliderClasses"
  >
    <div class="slider__wrapper-top">
      <p
        v-if="sliderTitle"
        class="slider__title"
      >
        {{ sliderTitle }}
      </p>
    </div>

    <div
      class="slider__wrapper"
      @mouseout="handleMouseOut"
      @mousemove="handleMouseMove"
    >
      <TheSliderButton
        :classes="buttonPrevClasses"
        @button-click="handleButtonClick(false)"
      />
      <TheSliderButton
        :classes="buttonNextClasses"
        @button-click="handleButtonClick(true)"
      />

      <div
        ref="sliderContainer"
        class="slider__container"
        @scroll.stop="updateButtonState"
      >
        <div
          ref="sliderList"
          class="slider__list"
          :style="{ width: sliderListWidth }"
        >
          <component
            :is="isTrackTheAppearance(item) ? 'IntersectionObserver' : 'div'"
            v-for="item of sliderList"
            :key="item.id"
            class="slider__item-wrapper"
            @appear="onAppear(item)"
          >
            <TheSliderItem
              :link="item.teaserUrl || item.url"
              :target="!!item.teaserUrl ? '_blank' : undefined"
              :title="item.title"
              :images="item.images"
              :default-img="item.defaultImg"
              :img-alt="item.alt || item.img_alt"
              @item-click="handleSliderItemClick"
            />
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { useWindowSize } from '@vueuse/core';
  import type { SliderItem } from '~/types';
  import TheSliderButton from '~/components/slider/TheSliderButton/TheSliderButton.vue';
  import TheSliderItem from '~/components/slider/TheSliderItem/TheSliderItem.vue';

  // отступ между элементами карусели
  const HORIZONTAL_PADDINGS_ITEM = 12;
  // ширина 1 элемента карусели + паддинги
  const WIDTH_ITEM_MOBILE = 160 + HORIZONTAL_PADDINGS_ITEM;
  const WIDTH_ITEM_DESKTOP = 160 + HORIZONTAL_PADDINGS_ITEM;
  // количество элементов, которые скроллятся при клике на кнопку
  const COUNT_ELEM_SCROLL = 3;
  // продолжительность анимации в секундах при клике на кнопку
  const TRANSITION_DURATION = 0.8;
  // паддинги по бокам у .slider__list
  const SLIDER_LIST_HORIZONTAL_PADDING = 24;
  const SLIDER_LIST_HORIZONTAL_PADDING_MOBILE = 16;

  export default defineNuxtComponent({
    name: 'TheSlider',

    components: {
      TheSliderButton,
      TheSliderItem,
    },

    props: {
      sliderList: {
        type: Array,
        required: true,
      },
      sliderTitle: {
        type: String,
        default: null,
      },
    },

    setup() {
      const { width: windowWidth } = useWindowSize();
      return { windowWidth };
    },

    data: () => ({
      isDisableScroll: false,
      isShowButtonPrev: false,
      isShowButtonNext: false,
      isDisableButtonPrev: false,
      isDisableButtonNext: false,
      isFirstSendAnalytics: true,
      middle: 0,
      sliderContainerWidth: 0,
    }),

    computed: {
      itemWidth() {
        return this.windowWidth >= 1280 ? WIDTH_ITEM_DESKTOP : WIDTH_ITEM_MOBILE;
      },

      sliderListWidth() {
        const listPadding =
          this.windowWidth >= 768
            ? SLIDER_LIST_HORIZONTAL_PADDING
            : SLIDER_LIST_HORIZONTAL_PADDING_MOBILE;
        return `${this.itemWidth * this.sliderList.length + listPadding * 2 - HORIZONTAL_PADDINGS_ITEM}px`;
      },

      sliderClasses(): object {
        return {
          'slider__available-scroll-prev': this.isShowButtonPrev,
          'slider__available-scroll-next': this.isShowButtonNext,
        };
      },

      buttonPrevClasses(): object {
        return {
          slider__button_disabled: this.isDisableButtonPrev,
          slider__button_prev: true,
        };
      },

      buttonNextClasses(): object {
        return {
          slider__button_disabled: this.isDisableButtonNext,
          slider__button_next: true,
        };
      },
    },
    mounted() {
      this.setSliderWidth();
      this.calculateMiddle();
      this.updateButtonState();

      (this.$refs.sliderContainer as HTMLElement).addEventListener(
        'scrollend',
        this.sendSuccessfulScrollAnalytics,
      );
    },

    beforeUnmount() {
      (this.$refs.sliderContainer as HTMLElement).removeEventListener(
        'scrollend',
        this.sendSuccessfulScrollAnalytics,
      );
    },

    methods: {
      handleMouseOut({ target }: MouseEvent): void {
        // Иначе дергается кнопка при наведении мыши на слайдер через кнопку.
        // См. https://youtrack.lifehacker.ru/issue/LH-840
        const eventFromButton = target && (target as HTMLElement)?.closest('.slider__button');
        if (eventFromButton) {
          return;
        }

        this.isShowButtonNext = false;
        this.isShowButtonPrev = false;
      },

      handleMouseMove({ clientX }: MouseEvent): void {
        if (clientX >= this.middle) {
          this.isShowButtonPrev = false;
          this.isShowButtonNext = true;
        } else {
          this.isShowButtonNext = false;
          this.isShowButtonPrev = true;
        }
      },
      handleSliderItemClick(link: string): void {
        // https://youtrack.lifehacker.ru/issue/LH-300
        // если главная
        this.$emit('onClickSlide', link);
      },
      calculateMiddle(): void {
        const { sliderContainer } = this.$refs;
        if (!sliderContainer) return;

        const box = (sliderContainer as HTMLElement).getBoundingClientRect();
        this.middle = box.left + (box.right - box.left) / 2;
      },
      updateButtonState(): void {
        const { sliderContainer, sliderList } = this.$refs;
        if (!sliderContainer || !sliderList) {
          return;
        }

        const { scrollLeft } = sliderContainer as HTMLElement;

        if (
          scrollLeft >=
          parseInt((sliderList as HTMLElement).style.width, 10) - this.sliderContainerWidth - 1
        ) {
          this.isDisableButtonNext = true;
          this.isDisableButtonPrev = false;
        } else if (scrollLeft <= 0) {
          this.isDisableButtonPrev = true;
          this.isDisableButtonNext = false;
        } else {
          this.isDisableButtonNext = false;
          this.isDisableButtonPrev = false;
        }
      },
      handleButtonClick(isNext: boolean = false): void {
        if (this.isDisableScroll) {
          this.sendScrollAnalytics(false);
          return;
        }

        const { sliderContainer, sliderList } = this.$refs;
        if (!sliderContainer || !sliderList) {
          this.sendScrollAnalytics(false);
          return;
        }

        const { scrollLeft } = sliderContainer as HTMLElement;
        const sliderListWidth = parseInt((sliderList as HTMLElement).style.width, 10);
        if (isNext && scrollLeft >= sliderListWidth - this.sliderContainerWidth) {
          this.sendScrollAnalytics(false);
          return;
        }
        if (!isNext && scrollLeft <= 0) {
          this.sendScrollAnalytics(false);
          return;
        }

        this.scroll(isNext);
      },
      sendScrollAnalytics(isSuccessful: boolean) {
        if (this.isFirstSendAnalytics) {
          this.$sendAnalyticsSnowPlow({
            event_name: 'Скролл_Лучшие предложения',
            par3: String(this.sliderTitle),
          });

          this.$sendYandexMetrika({
            level1: 'Скролл_Лучшие предложения',
            level4: isSuccessful ? 1 : 0,
          });

          this.isFirstSendAnalytics = false;
        }
      },
      sendSuccessfulScrollAnalytics() {
        this.sendScrollAnalytics(true);
      },
      scroll(isNext: boolean): void {
        this.isDisableScroll = true;

        const scroll: number = this.itemWidth * COUNT_ELEM_SCROLL;
        let afterScroll: number, maxScroll: number, isFullScroll: boolean, operator: string;

        const container = this.$refs.sliderContainer as HTMLElement;
        const sliderList = this.$refs.sliderList as HTMLElement;

        if (isNext) {
          afterScroll = container.scrollLeft + scroll;
          maxScroll = parseInt(sliderList.style.width, 10) - this.sliderContainerWidth;
          isFullScroll = !(afterScroll > maxScroll);
          operator = '-';
        } else {
          afterScroll = container.scrollLeft - scroll;
          maxScroll = 0;
          isFullScroll = !(afterScroll < maxScroll);
          operator = '+';
        }

        // если доступен полный скролл равный itemWidth * COUNT_ELEM_SCROLL
        if (isFullScroll) {
          sliderList.style.transitionDuration = `${TRANSITION_DURATION}s`;

          // Анимация выполняется с помощью translate3d, а не постепенным изменением свойства scrollleft
          // из-за дерганий в сафари на странице с большим количеством изображений
          sliderList.style.transform = `translate3d(${operator}${scroll}px, 0px, 0px)`;

          setTimeout(() => {
            this.setScrollLeft(isNext, scroll);
          }, TRANSITION_DURATION * 1000);
        } else {
          // доступная величина скролла
          let scrollComputed: number;

          if (isNext) {
            scrollComputed = scroll - (afterScroll - maxScroll);
          } else {
            scrollComputed = scroll - -afterScroll;
          }
          const transitionDurationComputed: number = Number(
            ((scrollComputed / scroll) * TRANSITION_DURATION).toFixed(1),
          );

          sliderList.style.transitionDuration = `${transitionDurationComputed}s`;
          sliderList.style.transform = `translate3d(${operator}${scrollComputed}px, 0px, 0px)`;

          setTimeout(() => {
            this.setScrollLeft(isNext, scrollComputed);
          }, transitionDurationComputed * 1000);
        }
      },
      setScrollLeft(isNext: boolean, scrollValue: number): void {
        const container = this.$refs.sliderContainer as HTMLElement;
        const sliderList = this.$refs.sliderList as HTMLElement;
        // после окончания анимации убираем стили анимации и устанавливаем свойство scrollLeft
        // Это нужно для того чтобы сохранить возможность скроллить карусель с трекпада и мыши
        sliderList.style.transitionDuration = '0s';
        sliderList.style.transform = 'translate3d(0,0,0)';

        if (isNext) {
          container.scrollLeft += scrollValue;
        } else {
          container.scrollLeft -= scrollValue;
        }

        this.isDisableScroll = false;
      },
      setSliderWidth(): void {
        this.sliderContainerWidth = parseInt(
          getComputedStyle(this.$refs.sliderContainer as HTMLElement).width,
          10,
        );
      },
      isTrackTheAppearance(article: IArticle | SliderItem): boolean {
        if ('testPixel' in article) {
          return !!article.testPixel?.length;
        }
        return false;
      },
      onAppear(article: IArticle | SliderItem): void {
        this.$emit('appear-item', article);
      },
    },
  });
</script>

<style lang="scss" scoped>
  @use '../_variables' as *;

  .slider {
    position: relative;

    padding: $paddingY 0;
    margin: 0;

    border: 1px solid $borderColor;

    background-color: $backgroundColor;
    overflow: hidden;

    @include tablet {
      padding: $paddingYTablet 0;
    }

    @include inside-article {
      padding: 8px 0 16px;
      margin-left: -16px;
      width: calc(100% + 32px);
      border: none;

      @include margin-y(16px);

      @include tablet {
        padding: 16px 0;
        margin-left: -24px;
        width: calc(100% + 48px);

        @include margin-y(24px);
      }
    }

    @include widget-above-comments {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    .single-article__content & {
      margin-left: -16px;
      margin-right: -16px;

      @include tablet {
        margin-left: -24px;
        margin-right: -24px;
      }
    }
  }

  .archive-page__widget-under-news .slider {
    margin-bottom: 0;
  }

  .slider__title {
    display: flex;
    align-items: center;
    margin: 0 16px 16px 15px;

    @include fontH2;

    @include tablet() {
      margin: -7px 0 16px 23px;
    }
  }

  .slider__wrapper-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  .slider__wrapper {
    width: 100%;
    height: 240px;
    overflow-y: hidden;
  }

  .slider__container {
    position: relative;

    width: 100%;
    height: 244px;

    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    scroll-behavior: auto;
    -webkit-overflow-scrolling: auto;
    -ms-overflow-style: none; // EDGE
    -webkit-overflow-scrolling: touch; // скроллинг в ios
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);

    &::-webkit-scrollbar {
      width: 0 !important; // Firefox
      height: 0;
      display: none;

      background: transparent;
    }
  }

  .slider__list {
    margin: 0;
    display: flex;
    padding-left: 16px;
    padding-right: 16px;

    scroll-behavior: auto;
    list-style: none;

    @include tablet() {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .slider__container::-webkit-scrollbar-track,
  .slider__list::-webkit-scrollbar-track,
  .slider__container::-webkit-scrollbar,
  .slider__list::-webkit-scrollbar,
  .slider__container::-webkit-scrollbar-thumb,
  .slider__list::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
</style>
