<template>
  <TheLink
    class="slider-item without-base-styles"
    data-cy="slider-item slider-item-image-link"
    :link="link"
    :title="title"
    :target="target"
    @click.native="$emit('item-click', link)"
  >
    <AppPicture
      class="slider-item__cover"
      :alt="imgAlt || title"
      :images="images"
      :default-images="defaultImg"
      :sizes-sliced="[160, 187]"
      :sizes-by-device="{ mobile: '187px', tablet: '187px' }"
    />
    <p
      class="slider-item__title"
      v-html="title"
    />
    <div class="slider-item__gradient" />
  </TheLink>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import AppPicture from '@/components/lh-ui/AppPicture/AppPicture.vue';

  import TheLink from '~/components/TheLink.vue';

  export default defineNuxtComponent({
    name: 'TheSliderItem',

    components: {
      TheLink,
      AppPicture,
    },

    props: {
      link: {
        type: String as PropType<string>,
        required: true,
      },

      title: {
        type: String as PropType<string>,
        required: true,
      },

      images: {
        type: Array as PropType<IArticleImg[]>,
        default: () => [],
      },

      defaultImg: {
        type: Array as PropType<IArticleImg[]>,
        default: () => [],
      },

      imgAlt: {
        type: String as PropType<string>,
        required: true,
      },

      target: {
        type: String as PropType<string>,
        default: null,
      },
    },
  });
</script>

<style lang="scss" scoped>
  @use '../_variables' as *;
  $transitionDuration: 0.3s;

  .slider-item {
    position: relative;
    z-index: 0;

    display: flex;
    align-items: flex-end;
    width: $itemWidth;
    min-width: $itemWidth;
    height: $itemHeight;
    min-height: $itemHeight;
    margin-right: 12px;
    padding: 8px;
    overflow: hidden;
    border-radius: 4px;
    text-decoration: none;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      border: 0 solid var(--main-color);
      box-sizing: border-box;
      left: 0;
      top: 0;
      border-radius: 4px;
      z-index: 1;
    }

    @include hover {
      &:before {
        border-width: 2px;
      }

      .slider-item__cover {
        transform: scale(1.1);
      }

      .slider-item__title {
        text-decoration-color: $textColor;
      }
    }
  }

  .slider-item__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $backgroundGradient;
  }

  .slider-item__cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    font-size: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: transform $transitionDuration ease-in-out;
  }

  .slider-item__title {
    @include fontSmallTextBtn;

    position: relative;
    z-index: 2;

    display: -webkit-box;

    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    color: $textColor;
    white-space: normal;

    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 1px;
    text-decoration-color: transparent;
    transition: text-decoration-color $transitionDuration ease-in-out;
  }
</style>
