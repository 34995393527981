<template>
  <button
    class="slider__button"
    data-cy="slider-button"
    :class="classes"
    aria-label="Кнопка для скролла слайдера"
    @click="$emit('button-click')"
  >
    <SvgAsset
      class="typo-form__close-button-icon"
      :content="arrowIcon"
    />
  </button>
</template>

<script lang="ts">
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';
  import ArrowContent from './assets/arrow.svg?raw';

  export default defineNuxtComponent({
    name: 'TheSliderButton',

    components: {
      SvgAsset,
    },

    props: {
      classes: {
        type: Object,
        required: true,
      },
    },

    computed: {
      arrowIcon() {
        return ArrowContent;
      },
    },
  });
</script>

<style src="./styles.scss" lang="scss" scoped></style>
